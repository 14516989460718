$fd-green: #43b02a;
$fd-dark-blue: #004165;
$fd-white: #ffffff;
$fd-dark-gray: #616365;
$fd-light-gray: #f4f4f4;
$fd-indigo-blue: #0072ce;
$fd-light-blue: #00a9e0;
$fd-alternate-blue: #297fca;
$fd-disabled-btn-background: #f0f0f0;
$fd-yellow: #ffe200;
$fd-dark-yellow: #e6cc00;
$fd-alert-yellow: #ffc107;
$fd-red: #c01324;
$substep-container-width: 200px;
$fs-color: #f2571a;
$fd-nav-icon: #93bee4;
$fd-nav-icon-focus:#297fca;
$fd-orange: #f56c08;
